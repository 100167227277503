import * as React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import Colors from '../utils/Colors';
import FontStyles from "../utils/FontStyles"
import { Feather } from "@expo/vector-icons"

function UserInfoCard(props) {
  return (
    <View style={styles.chartContainer}>
      <View style={{flexDirection: "row", alignItems: "center", marginBottom: 10}}>
        <Text style={[FontStyles.subhead, {flex: 1, fontWeight: "600", color: Colors.grayDark.alpha1 }]}>
          {props.title}
        </Text>
        <TouchableOpacity onPress={() => props.reloadData()} activeOpacity={0.9} style={{paddingLeft: 50}}>
          <Feather size={22} color={Colors.greenLightDark.alpha1} name={"refresh-cw"}/>
        </TouchableOpacity>
      </View>

      <View style={styles.rowContainer}>
        <Text style={[FontStyles.footnote, {flex: 1, color: Colors.greenLightDark.alpha1, fontWeight: "600" }]}>
          Toplam Neutec 5000 Aktif Kod
        </Text>
        <Text style={[FontStyles.subhead, { color: Colors.gray.alpha1, fontWeight: "800" }]}>
          {props.data.neutecMembershipCount || "?"}
        </Text>
      </View>

      <View style={styles.rowContainer}>
        <Text style={[FontStyles.footnote, {flex: 1, color: Colors.greenLightDark.alpha1, fontWeight: "600" }]}>
          Toplam Neu. Research Aktif Kod
        </Text>
        <Text style={[FontStyles.subhead, { color: Colors.gray.alpha1, fontWeight: "800" }]}>
          {props.data.neutecResearchMembershipCount || "?"}
        </Text>
      </View>

      <View style={[styles.rowContainer, {borderBottomWidth: 0}]}>
        <Text style={[FontStyles.footnote, {flex: 1, color: Colors.greenLightDark.alpha1, fontWeight: "600" }]}>
          Kayıtlı Push N. Sayısı
        </Text>
        <Text style={[FontStyles.subhead, { color: Colors.gray.alpha1, fontWeight: "800" }]}>
          {props.data.pushTokenCount || "?"}
        </Text>
      </View>
      
    </View>
  );
}

const styles=StyleSheet.create({
  chartContainer: {
    backgroundColor: Colors.white.alpha1,
    paddingHorizontal: 15,
    paddingVertical: 15,
    margin: 15,
    alignSelf: "baseline",
    borderRadius: 15,
    flex: 1,
    minWidth: 300
  },
  rowContainer: {
    width: "100%",
    borderColor: Colors.grayLight.alpha08,
    borderBottomWidth: 1.5,
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 15,
  }
})

export default UserInfoCard;