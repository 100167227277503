import React from 'react';
import {ActivityIndicator, Animated, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import Select from 'react-select';
import Colors from "../utils/Colors";
import FontStyles from "../utils/FontStyles";
import API from "../utils/API";
import {CommonActions} from "@react-navigation/native";
import DatePicker from "./DatePicker";
import {Feather} from "@expo/vector-icons";
import {PushNotificationTextInput} from "./PushNotificationTextInput";

const minimumNotificationFrequencyOptions = [
    {value: "RARELY", label: "Nadiren"},
    {value: "OCCASIONALLY", label: "Ara Sıra"},
    {value: "FREQUENTLY", label: "Sık Sık"}
]

const timeOfDayOptions = [...Array(24).keys()].map(hour=>{
    return {
        value: hour,
        label: String(hour).padStart(2, '0') + ":00",
        isDisabled: hour < 3 || 21 < hour,
    }
})

const dayOfWeekOptions = [
    {value: "SUNDAY", label: "Pazar"},
    {value: "MONDAY", label: "Pazartesi"},
    {value: "TUESDAY", label: "Salı"},
    {value: "WEDNESDAY", label: "Çarşamba"},
    {value: "THURSDAY", label: "Perşembe"},
    {value: "FRIDAY", label: "Cuma"},
    {value: "SATURDAY", label: "Cumartesi"},
];

const languageOptions = [
    {value: "en", label: "İngilizce"},
    {value: "tr", label: "Türkçe"},
];

const premiumStatusFilterOptions = [
    {value: null, label: "Filtre Yok"},
    {value: "PREMIUM", label: "Premium"},
    {value: "NON-PREMIUM", label: "Non-Premium"},
];

const numericalFilterOptions = [
    {value: null, label: "Filtre Yok"},
    {value: "gt=", label: ">"},
    {value: "gte=", label: "≥"},
    {value: "lt=", label: "<"},
    {value: "lte=", label: "≤"},
    {value: "eq=", label: "="},
    {value: "neq=", label: "≠"},
    {value: "btw=~", label: "Arasında"},
];


export default class PlannedPushNotificationEdit extends React.Component {

    constructor(props) {
        super(props);

        let data = this.props.data || {};

        this.state = {
            loading: false,
            id: data.id || undefined,
            minimumNotificationFrequency: data.minimumNotificationFrequency || "",
            accountSpanInDays: data.accountSpanInDays || "", // only in ACCOUNT-SPAN-DEPENDENT and ACCOUNT-SPAN-DEPENDENT-RECURRING types
            recurrenceFrequencyInDays: data.recurrenceFrequencyInDays || "", // only in ACCOUNT-SPAN-DEPENDENT-RECURRING type
            dayOfWeek: data.dayOfWeek || "", // only in DAY-OF-WEEK-DEPENDENT type
            exactDate: data.exactDate || "", // only in EXACT-DATE-DEPENDENT type
            text: Array.isArray(data.text) ? [...data.text] : [""],
            timeOfDay: typeof data.timeOfDay === 'number'? data.timeOfDay : null,
            language: data.language || "",
            notificationType: data.notificationType || "",
            premiumStatusFilter: data.premiumStatusFilter || null,
            ageFilter: data.ageFilter || null,
            assessmentGamePlayedCountFilter: data.assessmentGamePlayedCountFilter || null,
            trainingGamePlayedCountFilter: data.trainingGamePlayedCountFilter || null,
        }

        this.remainingCharAnimValue = new Animated.Value(178-this.state.text.length);
        this.remainingCharColorAnim = this.remainingCharAnimValue.interpolate({
            inputRange: [0, 60, 178],
            outputRange: ['rgb(217, 52, 52)', 'rgb(191, 148, 46)', 'rgb(54, 181, 47)'],
            extrapolate: 'clamp',
        })
    }

    generateNumericalFilterComponent(key, label){
        return (
            <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 12,}}>
                <Text style={{marginRight: 10, fontSize: 16}}>
                    {label + ":"}
                </Text>
                <Select
                    isSearchable={false}
                    isClearable={false}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    options={numericalFilterOptions}
                    onChange={({value})=>{this.setState({
                        [key]: value === null ? null : value
                    })}}
                    value={numericalFilterOptions.filter(opt => opt.value === this.state[key] || ( typeof opt.value === 'string' && typeof this.state[key] === 'string' && opt.value.replace(/[=~]/g, "") === this.state[key].split('=')[0] ) )[0]}
                    placeholder={"Seçiniz"}

                />
                {
                    this.state[key] &&
                    <TextInput
                        style={{borderRadius: 4, width: 60, height: 36, fontSize: 16, borderWidth: 0.5, marginLeft: 12, borderColor: '#cccccc', padding: 8}}
                        value={this.state[key].startsWith("btw=") ?
                            this.state[key].substr(4).split('~')[0] :
                            this.state[key].split("=")[1]}
                        maxLength={4}
                        keyboardType='numeric'
                        onChangeText={(text)=>{
                            let replaced = text.replace(/[^0-9]/g, '');
                            if(replaced.length > 0 && replaced.substr(0,1) === '0'){
                                replaced = replaced.substr(1);
                            }

                            this.setState({
                                [key]: this.state[key].split("=")[0] +
                                    "=" + replaced +
                                    (this.state[key].startsWith("btw") ?
                                        ("~" + this.state[key].split("=")[1].split("~")[1]) : "")
                            })
                        }}

                    />
                }
                {
                    this.state[key] && this.state[key].startsWith("btw=") &&
                    <TextInput
                        style={{borderRadius: 4, width: 60, height: 36, fontSize: 16, borderWidth: 0.5, marginLeft: 12, borderColor: '#cccccc', padding: 8}}
                        value={this.state[key].substr(4).split('~')[1]}
                        maxLength={4}
                        keyboardType='numeric'
                        onChangeText={(text)=>{
                            let replaced = text.replace(/[^0-9]/g, '');
                            if(replaced.length > 0 && replaced.substr(0,1) === '0'){
                                replaced = replaced.substr(1);
                            }

                            this.setState({
                                [key]: this.state[key].split("~")[0] + "~" + replaced
                            })
                        }}

                    />
                }
            </View>
        );
    }

    submitChanges = () => {
        this.setState({loading: true});

        let data = {...this.state};
        data.text = [...data.text];
        for(let i = 0; i < data.text.length; i++){
            data.text[i] = data.text[i].replace(/\s/g, ' ').replace(/\s{2,}/g, ' ').replace(/^\s+/g, '').replace(/\s+$/g, '');
            if(data.text[i].length === 0){
                data.text.splice(i,1);
            }
        }

        if(data.notificationType.length === 0){
            data.notificationType = null;
        }

        data["type"] = this.props.type;
        if(this.props.type === "ACCOUNT-SPAN-DEPENDENT"){
            delete data["dayOfWeek"];
            delete data["exactDate"];
            delete data["recurrenceFrequencyInDays"];
        } else if(this.props.type === "ACCOUNT-SPAN-DEPENDENT-RECURRING"){
            delete data["dayOfWeek"];
            delete data["exactDate"];
        } else if(this.props.type === "DAY-OF-WEEK-DEPENDENT"){
            delete data["accountSpanInDays"];
            delete data["exactDate"];
            delete data["recurrenceFrequencyInDays"];
        } else if(this.props.type === "EXACT-DATE-DEPENDENT"){
            delete data["dayOfWeek"];
            delete data["accountSpanInDays"];
            delete data["recurrenceFrequencyInDays"];
        }

        let id = data.id;

        if(data.id){
            delete data["id"];

            // send API request for updating.
            API.updatePlannedPushNotification(id, data)
                .then(()=>{
                    this.setState({loading: false})
                    if(typeof this.props.onSubmit === 'function') {
                        this.props.onSubmit();
                    }
                })
                .catch(e=>{
                    this.setState({loading: false})
                    if(e.errorCode === 7){
                        alert(e.errorMessage);
                        this.props.navigation.dispatch(
                            CommonActions.reset({
                                index: 0,
                                routes: [{ name: "Login" }]
                            })
                        )
                    }
                    else{
                        alert("Bir hata oluştu: " + e.errorMessage);
                    }
                })
        }
        else{
            // send API request for creating new.
            API.createPlannedPushNotification(data)
                .then(()=>{
                    this.setState({loading: false})
                    if(typeof this.props.onSubmit === 'function') {
                        this.props.onSubmit();
                    }
                })
                .catch(e=>{
                    this.setState({loading: false})
                    if(e.errorCode === 7){
                        alert(e.errorMessage);
                        this.props.navigation.dispatch(
                            CommonActions.reset({
                                index: 0,
                                routes: [{ name: "Login" }]
                            })
                        )
                    }
                    else{
                        alert("Bir hata oluştu: " + e.errorMessage);
                    }
                })
        }
    }

    render(){

        let title = "";
        if(this.props.type === "ACCOUNT-SPAN-DEPENDENT"){
            title += "Hesap Süresi Bazlı";
        }
        else if(this.props.type === "ACCOUNT-SPAN-DEPENDENT-RECURRING"){
            title += "Hesap Süresi Bazlı ve Tekrarlı";
        }
        else if(this.props.type === "DAY-OF-WEEK-DEPENDENT"){
            title += "Haftanın Günü Bazlı";
        }
        else if(this.props.type === "EXACT-DATE-DEPENDENT"){
            title += "Tam Tarihi Belirlenmiş";
        }

        title += " Bildirim " + (this.state.id ? "Güncelleme" : "Oluşturma")

        let optionalComponent = null;
        if(this.props.type === "ACCOUNT-SPAN-DEPENDENT" || this.props.type === "ACCOUNT-SPAN-DEPENDENT-RECURRING"){
            optionalComponent = (<View style={{flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                <Text style={{marginRight: 10, fontSize: 16}}>
                    {this.props.type === "ACCOUNT-SPAN-DEPENDENT" ? "Kayıttan kaç gün sonra:" : "Kayıttan kaç gün sonra başlıyor:"}
                </Text>
                <TextInput
                    style={{borderRadius: 4, width: 60, height: 36, fontSize: 16, borderWidth: 0.5, borderColor: '#cccccc', padding: 8}}
                    value={this.state.accountSpanInDays === null ? "" : String(this.state.accountSpanInDays)}
                    maxLength={4}
                    keyboardType='numeric'
                    onChangeText={(text)=>{
                        let replaced = text.replace(/[^0-9]/g, '');
                        if(replaced.length > 0 && replaced.substr(0,1) === '0'){
                            replaced = replaced.substr(1);
                        }

                        this.setState({
                            accountSpanInDays: replaced.length === 0 ? null : parseInt(replaced),
                        })
                    }}

                />
            </View>);
        }
        if(this.props.type === "ACCOUNT-SPAN-DEPENDENT-RECURRING"){
            optionalComponent = [optionalComponent];
            optionalComponent.push((<View style={{flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
                <Text style={{marginRight: 10, fontSize: 16}}>
                    Kaç günde bir tekrarlı:
                </Text>
                <TextInput
                    style={{borderRadius: 4, width: 60, height: 36, fontSize: 16, borderWidth: 0.5, borderColor: '#cccccc', padding: 8}}
                    value={this.state.recurrenceFrequencyInDays === null ? "" : String(this.state.recurrenceFrequencyInDays)}
                    maxLength={4}
                    keyboardType='numeric'
                    onChangeText={(text)=>{
                        let replaced = text.replace(/[^0-9]/g, '');
                        if(replaced.length > 0 && replaced.substr(0,1) === '0'){
                            replaced = replaced.substr(1);
                        }

                        this.setState({
                            recurrenceFrequencyInDays: replaced.length === 0 ? null : parseInt(replaced),
                        })
                    }}

                />
            </View>))
        }
        if(this.props.type === "DAY-OF-WEEK-DEPENDENT"){
            optionalComponent = (<View style={{flexDirection: 'row', alignItems: 'center', marginTop: 20,}}>
                <Text style={{marginRight: 10, fontSize: 16}}>
                    Haftanın Günü:
                </Text>
                <Select
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    options={dayOfWeekOptions}
                    onChange={({value})=>{this.setState({dayOfWeek: value})}}
                    value={dayOfWeekOptions.filter(opt => opt.value === this.state.dayOfWeek )[0]}
                    placeholder={"Seçiniz"}

                />
            </View>);
        }
        if(this.props.type === "EXACT-DATE-DEPENDENT"){
            optionalComponent = (<View style={{flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                <Text style={{marginRight: 10, fontSize: 16}}>
                    Tarih:
                </Text>
                <DatePicker value={this.state.exactDate}
                            onChange={event=>{this.setState({exactDate: event.target.value})}}
                            min={(new Date().toISOString().substr(0,10))}
                            style={{borderRadius: 4, width: 180, height: 36, fontSize: 16,
                                borderWidth: 0.5, borderColor: '#cccccc', paddingLeft: 12, paddingRight: 12,
                                fontFamily: 'sans-serif',
                            }}/>
            </View>);
        }

        return <View style={{flex: 1, marginHorizontal: 20}}>
            <Text style={{fontWeight: "bold", fontSize: 18}}>
                {title}
            </Text>

            {optionalComponent}
            
            <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 12,}}>
                <Text style={{marginRight: 10, fontSize: 16}}>
                    Minimum Seçili Bildirim Sıklığı:
                </Text>
                <Select
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    options={minimumNotificationFrequencyOptions}
                    onChange={({value})=>{this.setState({minimumNotificationFrequency: value})}}
                    value={minimumNotificationFrequencyOptions.filter(opt => opt.value === this.state.minimumNotificationFrequency )[0]}
                    placeholder={"Seçiniz"}
                
                />
            </View>

            <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 12,}}>
                <Text style={{marginRight: 10, fontSize: 16}}>
                    Günün Saati:
                </Text>
                <Select
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    options={timeOfDayOptions}
                    onChange={({value})=>{this.setState({timeOfDay: value})}}
                    value={timeOfDayOptions.filter(opt => opt.value === this.state.timeOfDay )[0]}
                    placeholder={"Seçiniz"}

                />
            </View>

            <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 12,}}>
                <Text style={{marginRight: 10, fontSize: 16}}>
                    Dil:
                </Text>
                <Select
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    options={languageOptions}
                    onChange={({value})=>{this.setState({language: value})}}
                    value={languageOptions.filter(opt => opt.value === this.state.language )[0]}
                    placeholder={"Seçiniz"}

                />
            </View>

            <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 12,}}>
                <Text style={{marginRight: 10, fontSize: 16}}>
                    Payload Tipi:
                </Text>
                <TextInput
                    style={{borderRadius: 4, width: 250, height: 36, fontSize: 16, borderWidth: 0.5, marginLeft: 12, borderColor: '#cccccc', padding: 8}}
                    value={this.state.notificationType}
                    maxLength={64}
                    onChangeText={(text)=>{
                        let replaced = text.replace(/[^A-Z\-]/g, '');
                        replaced = replaced.replace(/--/g, '-');

                        this.setState({
                            notificationType: replaced,
                        })
                    }}

                />
            </View>

            <Text style={{marginTop: 20, marginBottom: 4, marginRight: 10, fontSize: 16}}>
                {"Bildirim Metinleri: "}
                <Text style={{fontStyle: 'italic', fontSize: 13, color: Colors.grayDark.alpha07}}>
                    {["ACCOUNT-SPAN-DEPENDENT-RECURRING", "DAY-OF-WEEK-DEPENDENT"].includes(this.props.type) ?
                        "  (Bu bildirimler her tekrarda sıra ile gönderilecektir)" :
                        "  (Her kullanıcı bu bildirimlerden birini rastgele olarak alacaktır.)"}
                </Text>
            </Text>
            {
                this.state.text.map((item,index)=>{
                    return <PushNotificationTextInput
                        key={"texts-"+index}
                        value={this.state.text[index] || ""}
                        onChange={(text)=>{
                            let texts = [...this.state.text];
                            texts[index] = text;
                            this.setState({
                                text: texts
                            });
                        }}
                        onRemove={(this.state.text.length > 1 || index !== 0) ? ()=>{
                            let texts = [...this.state.text];
                            texts.splice(index, 1);
                            this.setState({
                                text: texts
                            });
                        } : null}
                    />;
                })

            }
            {<TouchableOpacity style={{alignSelf: 'flex-end', marginTop: 4, marginRight: 8}} activeOpacity={0.7}
                              onPress={()=>{
                                  this.setState({
                                      text: [...this.state.text, ""]
                                  })
                              }}>
                <Feather name={"plus"} size={20} color={Colors.green.alpha1}/>
            </TouchableOpacity>}
            <Text style={{marginTop: 12}}>---</Text>

            <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 12,}}>
                <Text style={{marginRight: 10, fontSize: 16}}>
                    Premium Durum Filtresi:
                </Text>
                <Select
                    isSearchable={false}
                    isClearable={false}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    options={premiumStatusFilterOptions}
                    onChange={({value})=>{this.setState({premiumStatusFilter: value})}}
                    value={premiumStatusFilterOptions.filter(opt => opt.value === this.state.premiumStatusFilter )[0]}
                    placeholder={"Seçiniz"}

                />
            </View>

            {
                this.generateNumericalFilterComponent('ageFilter', "Yaş Filtresi")
            }

            {
                this.generateNumericalFilterComponent('assessmentGamePlayedCountFilter', "Tracking Sayısı Filtresi")
            }

            {
                this.generateNumericalFilterComponent('trainingGamePlayedCountFilter', "Training Sayısı Filtresi")
            }

            <TouchableOpacity style={[styles.sendButton, {marginTop: 24}]} onPress={this.submitChanges} activeOpacity={0.9}>
                {
                    this.state.loading ?
                        <ActivityIndicator color={"white"}/>
                        :
                        <Text style={[FontStyles.caption1, {color: Colors.white.alpha1, fontWeight: "600" }]}>
                            {this.state.id ? "Güncelle" : "Oluştur"}
                        </Text>
                }
            </TouchableOpacity>

        </View>;
    }
}

const styles = StyleSheet.create({
    sendButton: {
        justifyContent: "center",
        alignItems: "center",
        alignSelf: 'flex-end',
        height: 40,
        width: 120,
        borderRadius: 5,
        backgroundColor: Colors.greenLight.alpha1,
        paddingHorizontal: 10,
    },
})