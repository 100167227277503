// In App.js in a new project

import * as React from 'react';
import { Button, View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import LoginScreen from "./Screens/Login.js";
import HomeScreen from "./Screens/Home.js";
import API from "./utils/API.js";
import { AppLoading } from 'expo';
import moment from 'moment';

const momentRelativeTimes = {
	"tr": {
    months : 'Ocak_Şubat_Mart_Nisan_Mayıs_Haziran_Temmuz_Ağustos_Eylül_Ekim_Kasım_Aralık'.split('_'),
    monthsShort : 'Oca._Şub._Mar._Nis._May._Haz._Tem._Ağu._Eyl._Eki._Kas._Ara'.split('_'),
		relativeTime: {
			future: '%s sonra',
			past: '%s önce',
			s:  '1 saniye',
			ss: '%ssn',
			m:  '1 dakika',
			mm: '%ddk',
			h:  '1 saat',
			hh: '%ds',
			d:  '1 gün',
			dd: '%dg',
			M:  'ay',
			MM: '%da',
			y:  'yıl',
			yy: '%dY'
		}
	},
	"en": {
		relativeTime: {
			future: 'in %s',
			past: '%s ago',
			s:  'seconds',
			ss: '%ss',
			m:  'a minute',
			mm: '%dm',
			h:  'an hour',
			hh: '%dh',
			d:  'a day',
			dd: '%dd',
			M:  'a month',
			MM: '%dM',
			y:  'a year',
			yy: '%dY'
		}
	}
}

moment.defineLocale("tr", momentRelativeTimes["tr"])
moment.locale("tr")

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.allowFontScaling = true;
Text.defaultProps.selectable = true;
Text.defaultProps.maxFontSizeMultiplier = 1.125
const Stack=createStackNavigator();

export default class App extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state={
      loading: false,
      isLogin: false,
      userReady: false
    }

    this.linking = {
		  prefixes: ['https://panel.beynex.com'],
		  config: {
			  screens: {
				  Home: {
				  	screens: {
						Dashboard: 'dashboard',
						Users: 'users',
						Support: 'support',
						PushNotification: 'push-notifications',
						UserDetail: 'users/:userId',
						PlannedPushNotification: 'push-notifications/planned',
						NewAssessmentPushNotification: 'push-notifications/new-assessment',
						Blog: 'blog',
						BlogDetail: 'blog/:slug',
					}
				  },
				  Login: 'login',
			  }
		  },
	  };
  }

  getUser = async () => {
    let user = await API.getCurrentUser()
    if(user){
      this.setState({ userReady: true, isLogin: true})
    }else{
      this.setState({ userReady: true, isLogin: false})
    }
  }

  render() {
    if (!this.state.isReady || !this.state.userReady) {
      return (
        <AppLoading
          startAsync={this.getUser}
          onFinish={() => this.setState({ isReady: true })}
          onError={console.warn}
        />
      )
    }

    return (
      <NavigationContainer linking={this.linking}>
        <Stack.Navigator headerMode="none" initialRouteName={this.state.isLogin ? undefined : "Login"}>
					<Stack.Screen name="Home" component={HomeScreen} options={{title: "Anasayfa | Beynex Panel"}}/>
					<Stack.Screen name="Login" component={LoginScreen} options={{title: "Giriş | Beynex Panel"}}/>
        </Stack.Navigator>
      </NavigationContainer>
    );
  }
}

