import * as React from 'react';
import { Image, View, Text, TouchableOpacity, Dimensions, Animated, StyleSheet, TextInput, ActivityIndicator } from 'react-native';
import Colors from '../utils/Colors';
import FontStyles from "../utils/FontStyles";
import * as Device from 'expo-device';

export default class Home extends React.PureComponent {
  constructor(props) {
    super(props);
    this.animVal=this.props.firstSelected ? new Animated.Value(1):new Animated.Value(0);
    this.state={
      
    }
  }

  animateButton=(value, isInstant) => {
    Animated.timing(this.animVal, {
      toValue: value,
      duration: isInstant ? 0 : 300,
      useNativeDriver: true,
    }).start();
  }

  render() {

    let IconFamily = this.props.iconFamily;

    return (
      <TouchableOpacity style={[this.props.device != "PHONE" ? styles.buttonContainer : styles.buttonContainerMobile]} onPress={this.props.onPress} activeOpacity={0.9}>
        <IconFamily name={this.props.iconName} size={21} color={Colors.white.alpha1} />
        {
          this.props.device != "PHONE" &&
          <Text style={[FontStyles.callout, { color: Colors.white.alpha1, fontWeight: "600", marginLeft: 15 }]}>
            {this.props.title}
          </Text>
        }
        
        <Animated.View style={[this.props.device != "PHONE" ? styles.animatedView : styles.animatedViewMobile, { opacity: this.animVal }]}>
          <IconFamily name={this.props.iconName} size={21} color={Colors.greenLight.alpha1} />
          {
            this.props.device != "PHONE" &&
            <Text style={[FontStyles.callout, { color: Colors.greenLight.alpha1, fontWeight: "600", marginLeft: 15 }]}>
              {this.props.title}
            </Text>
          }
        </Animated.View>
      </TouchableOpacity>
    )
  }

}

const styles=StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "white",
  },
  buttonContainerMobile: {
    flex:1,
    height: 60,
    justifyContent:"center",
    alignItems: "center"
  },
  animatedViewMobile: {
    justifyContent:"center",
    alignItems: "center",
    width: "100%",
    height: 40,
    borderRadius: 10,
    backgroundColor: Colors.white.alpha1,
    position: "absolute",
    alignItems: "center",
  },
  buttonContainer: {
    width: "100%",
    paddingVertical: 10,
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: 20,
    marginTop: 20,
    borderRadius: 10,
  },
  animatedView: {
    width: "100%",
    height: "100%",
    borderRadius: 10,
    backgroundColor: Colors.white.alpha1,
    position: "absolute",
    top: 0,
    left: 0,
    paddingLeft: 20,
    flexDirection: "row",
    alignItems: "center",

  }
})
