import * as React from 'react';
import {Image, View, Text, TouchableOpacity, StyleSheet, Switch, TextInput, ActivityIndicator} from 'react-native';
import Colors from '../utils/Colors';
import FontStyles from "../utils/FontStyles";
import {Entypo, Feather} from "@expo/vector-icons";
import * as Device from 'expo-device';
import { ScrollView } from 'react-native-gesture-handler';
import TrackingGamesChart from '../components/TrackingGamesChart';
import UserDetailTrackingLineChart from '../components/UserDetailTrackingLineChart';
import ActiveLifeLineChart from '../components/ActiveLifeLineChart';
import API from '../utils/API';
import Loading from "../components/Loading";
import moment from "moment"
import TrackingGamePieChart from '../components/TrackingGamePieChart';
import LightAdmins from '../utils/LightAdmins.json';
import {CommonActions} from "@react-navigation/native";
import Modal from '../components/Modal';

const traningGameNames={
  842: "Renkler ve Şekiller",
  961: "Ünlü Çiftler",
  274: "Hafıza Kartları",
  275: "Hafıza Kartları Zor",
  498: "Santral",
  605: "Balık Sürüsü",
  342: "Kuşları Besle",
  721: "Aynı-Farklı",
  803: "Sırala Beni!",
  546: "Bir İşlem",
  315: "200 Uzaylı",
  189: "Şekil Avcısı",
  648: "Kaybolan Rakamlar",
  113: "Dönen Kareler",
}

const trackingGamesTR={
  "ARITHMETIC": "Aritmetik",
  "ATTENTION": "Dikkat",
  "FLEXIBILITY": "Esneklik",
  "LANGUAGE": "Dil",
  "MEMORY": "Hafıza",
  "PROBLEM-SOLVING": "Problem",
  "SPEED": "Hız",
  "VISUAL-PERCEPTION": "Görsel"
}

const ageRanges = [
  {key:"fortyMinus", min:null, max:40,text:"0-40"},
  {key:"fortyOneToFiftyFive", min:41, max:55,text:"41-55"},
  {key:"fiftySixToSeventy", min:56, max:70,text:"56-70"},
  {key:"seventyOnePlus", min:71, max:null,text:"71+"}
]

const suffixForAgeRanges = {
  avg:"AverageScore",
  std:"ScoreStandardDeviation",
}

export default class UsersDetail extends React.PureComponent {
  constructor(props) {
    super(props)
    this.trainingDataEmpty = true
    this.state={
      mediaBasePath: "",
      device: "DESKTOP",
      selectedTest: "Hafıza",
      selectedTestIndex: 0,
      chartType: 0,
      userData: {},
      activeLifeQuestionsAnswers: [],
      assessmentData: null,
      trainingData: null,
      latestMembership: {},
      latestDeviceInfo: null,
      ageKey:null,
      lightAdmin: true,
      newMembershipEnableDementiaTest: false,
      newMembershipInterval: 'MONTHLY',
      newMembershipSource: 'SPECIAL',
      newMembershipSending: false,
      deleteUserSending: false,
      emailVerifySending: false,
      dementiaTestResults: [],
      beynexPerformanceIndex: null,
    }
    this.newMembershipIntervalCount = "1";
    this.deleteUserMfaCode = "";
  }

  componentDidMount() {
    this.getDeviceType()
    this.getUserDetails()
  }

  getUserDetails=() => {
    API.getCurrentUser().then((currUser)=>{
      let lightAdmin = LightAdmins.includes(currUser.id);
      this.setState({
        lightAdmin: lightAdmin,
      })
      API.getUser(this.props.route.params.userId).then((response) => {
        if(lightAdmin && !(response.latestMembership && response.latestMembership.source === "NEUTEC-RESEARCH-CAMPAIGN")){
          alert("Bu kullanıcı görüntüleme yetkilerinizin dışında.");
          this.props.navigation.navigate('Users');
          return;
        }

        if(response.user.username) {
          this.props.navigation.setOptions({title: `Kullanıcı (${response.user.username}) | Beynex Panel`});
        }

        let ageKey = null
        if(response.user.birthYear){
          let userAge = new Date().getFullYear() - response.user.birthYear
          for(let i = 0; i < ageRanges.length; i++){
            if( (userAge >= ageRanges[i].min  && userAge <= ageRanges[i].max )|| ageRanges[i].max === null){
              ageKey = ageRanges[i]
              break
            }
          }
        }

        this.setState({
          userData: response.user,
          activeLifeQuestionsAnswers: this.generateActiveLifeQuestionData(response.activeLifeQuestionsAnswers),
          assessmentData: this.generateTrackingDatas(response.assessmentData), //aarray of array
          trainingData: this.generateTrainingDatas(response.trainingData),
          latestMembership: response.latestMembership,
          latestDeviceInfo: response.latestDeviceInfo,
          mediaBasePath: response.mediaBasePath,
          ageKey:ageKey,
          dementiaTestResults: response.dementiaTestResults,
          beynexPerformanceIndex: response.beynexPerformanceIndex
        }, () => {
          this.loading.hide();
        })
      }).catch((e) => {
        this.loading && this.loading.hide();
        if(e.errorCode === 7){
          alert(e.errorMessage);
          this.props.navigation.dispatch(
              CommonActions.reset({
                index: 0,
                routes: [{ name: "Login" }]
              })
          )
        }
      })
    }).catch(e=>{

    })


  }

  generateTrackingDatas=(data) => {
    let nameKeys=Object.keys(data)
    let dataArr=[]
    for (let i=0; i<nameKeys.length; i++) {
      let newObj={};
      newObj = data[nameKeys[i]];
      newObj["name"]=nameKeys[i];
      newObj["nameTurkish"]=trackingGamesTR[nameKeys[i]];
      newObj["results"]=this.sortRegistrationCounts(data[nameKeys[i]].results);
      dataArr.push(newObj);
    }
    return dataArr;
  }

  generateTrainingDatas=(data) => {
    let keys = Object.keys(data)
    let dataArr = []

    for (let i=0; i < keys.length; i++) {
      let newObj={}
      newObj["data"]=data[keys[i]]
      newObj["name"]=traningGameNames[keys[i]]
      dataArr.push(newObj)
      this.trainingDataEmpty =data[keys[i]].length == 0 ? true : false
    }
    return dataArr
  }

  sortRegistrationCounts=(d) => {
    let newData=[]
    for (let i=0; i<d.length; i++) {
      newData.push({ date: moment(d[i].createdAt).format('X'), value: d[i].score })
    }
    return newData
  }

  getDeviceType=async () => {
    let device=await Device.getDeviceTypeAsync();
    this.setState({ device: Device.DeviceType[device] })
  }

  generateActiveLifeQuestionData=(raw) => {
    if (raw.length==0) {
      return []
    }

    let data=[]
    let keys=Object.keys(raw[0].answers)

    for (let i=0; i<keys.length; i++) {
      let obj={}
      obj[keys[i]]=[]
      data.push(obj)
    }

    for (let k=0; k<raw.length; k++) {
      for (let j=0; j<keys.length; j++) {
        data[j][keys[j]].push({ date: raw[k].answeredAt, value: raw[k].answers[keys[j]] })
      }
    }
    return data
  }

  activeLifeQuestions=() => {
    return (
      <View style={styles.activeLifeContainer}>
        {
          this.state.activeLifeQuestionsAnswers.length!=0&&this.state.activeLifeQuestionsAnswers.map((item, index) => {
            let key=Object.keys(item)[0]
            return (
              <View key={index+"1234"} style={styles.alqContainer}>
                <ActiveLifeLineChart
                  data={item[key]}
                  title={key}
                />
              </View>
            )
          })
        }
      </View>
    )
  }

  trainingPieChartCard=() => {
    if (this.state.trainingData && this.trainingDataEmpty == false) {
      return (
        <View style={styles.pieContainer}>
          <View style={{ flex: 3, minWidth: 300, justifyContent: "center", alignItems: "center" }}>
            <TrackingGamePieChart
              data={this.state.trainingData}
            />
          </View>
          <View style={{ flex: 2, paddingLeft: 20, minWidth: 300, borderLeftWidth: 2, borderColor: Colors.grayLight.alpha1 }}>
            <Text style={[FontStyles.footnote, { fontWeight: "600", color: Colors.dark.alpha1 }]}>
              {"Egzersiz Oyunları Oynama"}
            </Text>
            {
              this.state.trainingData.sort((a,b) => {return b.data.length - a.data.length}).map((item, index) => {
                return (
                  <View key={item.name + index + "f"} style={{ flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <Text style={[FontStyles.caption1, { flex: 1, color: Colors.dark.alpha1 }]}>
                      {item.name+":"}
                    </Text>
                    <Text style={[FontStyles.footnote, { fontWeight: "600", marginTop: 17.5, color: Colors.greenLight.alpha1 }]}>
                      {item.data?item.data.length:"-"}
                    </Text>
                  </View>
                )
              })
            }
          </View>
        </View>
      )
    }

    return (
      <Text style={[FontStyles.footnote, { fontWeight: "600", textAlign: "center", marginTop: 20, alignSelf: "center", color: Colors.dark.alpha1 }]}>
        {"Henüz egzersiz oyunu oynanmamış."}
      </Text>
    )
  }

  changeLightModeStatus = (value) => {
    let mode = value ? "light mode" : "normal mode";
    if(confirm("Bu aksiyonu alarak kullanıcıyı " + mode + "'a alacaksınız?\nEmin misiniz?")){
      API.changeUserLightModeStatus(this.props.route.params.userId,value).then(()=>{
        let obj = {...this.state.userData};
        obj.lightMode = value;
        this.setState({userData:obj},()=>{
        })
      }).catch((e)=>{
        alert(e.errorMessage)
      })
    }
  }

  goBackToUsers = (forceUsersRefresh) => {
    let routesInStack = this.props.navigation.dangerouslyGetState().routes;
    if(routesInStack.length > 1 && routesInStack[routesInStack.length-2].name === "Users"){
      let navigationReference = this.props.navigation;
      navigationReference.goBack();
      if(forceUsersRefresh){
        setTimeout(()=>{
          navigationReference.replace("Users");
        },500);
      }
    }
    else{
      this.props.navigation.replace('Users');
    }
  }

  dementiaTestEnableSwitch = (value) => {
    this.setState({newMembershipEnableDementiaTest: value})
  }

  sendNewMembership = () => {
    if(isNaN(parseInt(this.newMembershipIntervalCount)) || parseInt(this.newMembershipIntervalCount) < 1 ){
      alert('Üyelik süresi bir pozitif tamsayı olmalıdır.');
      return;
    }

    if(!confirm("Girdiğiniz bilgilerden ve doğru kullanıcı ile işlem yaptığınızdan emin misiniz?\n\nBu işlem veritabanına elle müdahale olmadan geri alınamaz!")){
      return;
    }

    this.setState({newMembershipSending: true});

    API.createNewMembership(this.props.route.params.userId, this.state.newMembershipSource, this.state.newMembershipInterval,
        parseInt(this.newMembershipIntervalCount), this.state.newMembershipEnableDementiaTest)
        .then((response)=>{
          this.setState({
            newMembershipEnableDementiaTest: false,
            newMembershipInterval: 'MONTHLY',
            newMembershipSending: false,
            latestMembership: response.membership,
            userData: response.user,
          });
          this.newMembershipIntervalCountInput.setNativeProps({text: "1"});
          this.newMembershipIntervalCount = "1";
          this.newMembershipModal.closeModal();
        })
        .catch((e)=>{
          alert(e.errorMessage);
          if(e.errorCode === 7){
            this.props.navigation.dispatch(
                CommonActions.reset({
                  index: 0,
                  routes: [{ name: "Login" }]
                })
            )
          } else {
            this.setState({newMembershipSending: false,})
          }
        })

  }

  enterActivationCode = () => {
    let activationCode = prompt("Aktivasyon kodunu giriniz.\n(⚠️ Kullanıcı adı ile beraber verilen kodlarda yalnızca şifre kısmını giriniz.)");
    if(typeof activationCode !== 'string') {
      return;
    }
    activationCode = activationCode.replace(/\s/g, '');
    if(activationCode.length < 3){
      alert('Lütfen geçerli bir aktivasyon kodu girin.');
      this.enterActivationCode();
      return;
    }

    if(!confirm('Bu kullanıcı için "' + activationCode + '" aktivasyon kodunu kullanmak istediğinize emin misiniz?')){
      return;
    }

    API.submitActivationCodeForUser(this.props.route.params.userId, activationCode)
        .then((response)=>{
          this.setState({
            latestMembership: response.membership,
            userData: response.user,
          });
          alert("Kod aktivasyonu başarıyla tamamlandı. ✅");
        })
        .catch((e)=>{
          alert(e.errorMessage);
          if(e.errorCode === 7){
            this.props.navigation.dispatch(
                CommonActions.reset({
                  index: 0,
                  routes: [{ name: "Login" }]
                })
            )
          }
        })


  }

  onChangeDeleteUserMfaText = (text) => {
    //special case
    if(/^\d{3} $/.test(text)){
      text = text.substr(0,2);
    }

    let newText = '';
    newText = text.replace(/\D/g,''); // remove spaces (and non-digits all characters) first for analysis.

    if(newText.length >= 3){ // put first space (this one is put right after the 3. character is typed)
      newText = newText.substr(0,3) + '  ' + newText.substr(3);
    }
    if(newText.length === 9){
      newText = newText.substr(0,7) + '  ' + newText.substr(7);
    } else if(newText.length >= 10){
      newText = newText.substr(0,8) + '  ' + newText.substr(8, 2);
    }

    this.deleteUserMfaInput.setNativeProps({text: newText});
    this.deleteUserMfaCode = newText.replace(/\D/g,'');
  }

  onPressEmailVerifyButton = () => {
    if(!confirm("\"" + this.state.userData.email + "\" adresinin geçerli ve bu kullanıcıya ait olduğunu onaylıyor musunuz?")){
      return null;
    }

    this.setState({emailVerifySending: true});
    API.verifyEmailAddressOfUser(this.state.userData.id)
        .then(res=>{
          let copyUserData = JSON.parse(JSON.stringify(this.state.userData));
          copyUserData.emailVerified = true;
          this.setState({userData: copyUserData, emailVerifySending: false});
        })
        .catch(e=>{
          alert(e.errorMessage);
          if(e.errorCode === 7){
            this.props.navigation.dispatch(
                CommonActions.reset({
                  index: 0,
                  routes: [{ name: "Login" }]
                })
            )
          } else {
            this.setState({emailVerifySending: false,})
          }
        })
  }

  sendDeleteUser = () => {
    if(/^[0-9]{6,8}$/.test(this.deleteUserMfaCode) === false){
      alert('OTP Kodu 6, 7 veya 8 haneli olmalıdır.');
      return;
    }

    if(!confirm("Yaptığınız bu işlem hiçbir şekilde geri alınamaz. " + this.state.userData.username + " kullanıcısını silmek istedinize emin misiniz?")){
      return;
    }

    this.setState({deleteUserSending: true});

    API.deleteUser(this.state.userData.id, this.deleteUserMfaCode)
        .then(()=>{
          this.setState({
            deleteUserSending: false,
          });

          alert("Silme işlemi başarıyla gerçekleşti. \"Kullanıcılar\" sayfasına yönlendiriliyorsunuz.");

          this.goBackToUsers(true);

        })
        .catch((e)=>{
          alert(e.errorMessage);
          if(e.errorCode === 7){
            this.props.navigation.dispatch(
                CommonActions.reset({
                  index: 0,
                  routes: [{ name: "Login" }]
                })
            )
          } else {
            this.setState({deleteUserSending: false,})
          }
        })
  }

  displayTimeWithTimezone = (time) => {
    let m = moment(time);
    let timezone = m.format('Z').split(":")[0];
    if (timezone.substr(1,1) === "0") {
      timezone = timezone.substr(0,1) + timezone.substr(2);
    }
    return m.format("Do MMMM YYYY , HH:mm") + " (" + timezone + ")"
  }

  render() {
    let userImg=this.state.userData.photo? { uri: this.state.mediaBasePath+"~"+this.state.userData.photo }:require("../assets/images/dummy.png")
    return (
      <View style={{ flex: 1 }}>
        <ScrollView
          style={{ flex: 1 }}
          contentContainerStyle={{ paddingBottom: 30 }}
        >
          <View style={[styles.container, { paddingTop: this.state.device==="PHONE"? 10:20 }]}>
            <View style={[styles.header, { height: this.state.device==="PHONE"? 56:70 }]}>
              <TouchableOpacity style={styles.goBackButton} onPress={() => this.goBackToUsers()} activeOpacity={0}>
                <Feather name={"arrow-left"} size={23} color={Colors.greenLight.alpha1} />
                <Text numberOfLines={1} style={[FontStyles.footnote, { marginLeft: 10, color: Colors.greenLight.alpha1, fontWeight: "600" }]}>
                  Kullanıcılar
                </Text>
              </TouchableOpacity>
              <View style={{ flex: 1, alignItems: "flex-end", paddingRight: 15 }}>
                <Text selectable style={[FontStyles.body, { textAlign: "right", color: Colors.dark.alpha1, fontWeight: "600" }]}>
                  {this.state.userData.username}
                </Text>
                <Text selectable style={[FontStyles.caption2, { marginTop: 5, color: Colors.dark.alpha05, fontWeight: "500" }]}>
                  <Text style={{fontWeight: '800'}}>
                    ID:
                  </Text>
                  <Text>
                    {" " + this.state.userData.id}
                  </Text>
                </Text>
              </View>
              <Image style={styles.headerImg} resizeMode="cover" source={userImg} />
            </View>

            <View style={styles.userCardContainer}>
              {/* <View style={styles.imageContainer}>
                <Image style={[styles.userImage, { width: this.state.device==="PHONE"? 60:100, height: this.state.device==="PHONE"? 60:100, borderRadius: this.state.device==="PHONE"? 30:50 }]} resizeMode={"cover"} source={userImg} />
                <Text style={[FontStyles.title3, { fontWeight: "600", color: Colors.dark.alpha1, marginTop: "7.5%" }]}>
                  {this.state.userData.username}
                </Text>
                <Text style={[FontStyles.footnote, { fontWeight: "500", color: this.state.userData.lightMode ? Colors.greenLight.alpha1 : Colors.red.alpha1, marginTop: 10 }]}>
                  {"Light Mode: " + (this.state.userData.lightMode ? "AÇIK" : "KAPALI")}
                </Text>
              </View> */}

              <View style={styles.infoContainer}>
                <Text style={[FontStyles.caption1, { fontWeight: "600", color: Colors.greenLight.alpha1 }]}>
                  Kişisel Bilgiler
                </Text>
                <View style={styles.infoRowContainer}>
                  <Text style={[FontStyles.caption1, { fontWeight: "400", color: Colors.gray.alpha07 }]} >
                    {"Yaş: "}
                  </Text>
                  <Text style={[FontStyles.caption1, { fontWeight: "500", color: Colors.dark.alpha1 }]} selectable={true}>
                    {this.state.userData.birthYear? `${new Date().getFullYear()-this.state.userData.birthYear} (${this.state.userData.birthYear})`:"-"}
                  </Text>
                </View>
                <View style={styles.infoRowContainer}>
                  <Text style={[FontStyles.caption1, { fontWeight: "400", color: Colors.gray.alpha07 }]}>
                    {"Eğitim: "}
                  </Text>
                  <Text style={[FontStyles.caption1, { fontWeight: "500", color: Colors.dark.alpha1 }]} selectable={true}>
                    {this.state.userData.educationLevel||"-"}
                  </Text>
                </View>
                <View style={styles.infoRowContainer}>
                  <Text style={[FontStyles.caption1, { fontWeight: "400", color: Colors.gray.alpha07 }]}>
                    {"Konum: "}
                  </Text>
                  <Text style={[FontStyles.caption1, { fontWeight: "500", color: Colors.dark.alpha1 }]} selectable={true}>
                    {this.state.userData.signupLocation? `${this.state.userData.signupLocation[0]}, ${this.state.userData.signupLocation[2]}, ${this.state.userData.signupLocation[3]}`:"-"}
                  </Text>
                </View>
                <View style={styles.infoRowContainer}>
                  <Text style={[FontStyles.caption1, { fontWeight: "400", color: Colors.gray.alpha07 }]}>
                    {"Tel No: "}
                  </Text>
                  <Text style={[FontStyles.caption1, { fontWeight: "500", color: Colors.dark.alpha1 }]} selectable={true}>
                    {this.state.userData.phoneNumber||"-"}
                  </Text>
                </View>
                <View style={styles.infoRowContainer}>
                  <Text style={[FontStyles.caption1, { fontWeight: "400", color: Colors.gray.alpha07 }]}>
                    {"Light Mode"}
                  </Text>
                  <Switch
                    trackColor={{false: Colors.red.alpha1, true: Colors.greenLight.alpha1}}
                    value={this.state.userData.lightMode}
                    onValueChange={value=>{this.changeLightModeStatus(value)}}
                  />
                </View>
              </View>
              <View style={styles.infoContainer}>
                <Text style={[FontStyles.caption1, { fontWeight: "600", color: Colors.greenLight.alpha1 }]}>
                  Hesap Bilgileri
                </Text>
                <View style={styles.infoRowContainer}>
                  <Text style={[FontStyles.caption1, { fontWeight: "400", color: Colors.gray.alpha07 }]}>
                    {"E-Posta: "}
                  </Text>
                  <Text style={[FontStyles.caption1, { fontWeight: "500", color: Colors.dark.alpha1 }]} selectable={true}>
                    {this.state.userData.email+" "}
                    <Feather name={this.state.userData.emailVerified? "check-circle":"x"} color={this.state.userData.emailVerified? Colors.greenLight.alpha1:Colors.red.alpha1} />
                  </Text>
                </View>
                {
                  this.state.userData.emailVerified ? null : <TouchableOpacity style={styles.emailVerifyButton} onPress={this.onPressEmailVerifyButton} activeOpacity={0.9}>
                      {
                        this.state.emailVerifySending ?
                            <ActivityIndicator color={"white"} size={14}/>
                            :
                            <Text style={[FontStyles.caption2, {color: Colors.white.alpha1, fontWeight: "600" }]}>
                              E-posta'yı doğrula
                            </Text>
                      }
                  </TouchableOpacity>
                }
                <View style={styles.infoRowContainer}>
                  <Text style={[FontStyles.caption1, { fontWeight: "400", color: Colors.gray.alpha07 }]}>
                    {"Üyelik Tarihi:"}
                  </Text>
                  <Text style={[FontStyles.caption1, { fontWeight: "500", color: Colors.dark.alpha1 }]} selectable={true}>
                    {this.displayTimeWithTimezone(this.state.userData.createdAt)}
                  </Text>
                </View>
                <View style={styles.infoRowContainer}>
                  <Text style={[FontStyles.caption1, { fontWeight: "400", color: Colors.gray.alpha07 }]}>
                    {"App Dili:"}
                  </Text>
                  <Text style={[FontStyles.caption1, { fontWeight: "500", color: Colors.dark.alpha1 }]} selectable={true}>
                    {this.state.userData.language === "tr" ? "Türkçe" : (this.state.userData.language === "en" ? "İngilizce": this.state.userData.language)}
                  </Text>
                </View>
                <View style={styles.infoRowContainer}>
                  <Text style={[FontStyles.caption1, { fontWeight: "400", color: Colors.gray.alpha07 }]}>
                    {"Saat Dilimi:"}
                  </Text>
                  <Text style={[FontStyles.caption1, { fontWeight: "500", color: Colors.dark.alpha1 }]} selectable={true}>
                    {(typeof this.state.userData.timezoneOffset === 'number')? ("GMT " + (this.state.userData.timezoneOffset >= 0 ? "+" : "") +this.state.userData.timezoneOffset/60):"-"}
                  </Text>
                </View>
                <View style={styles.infoRowContainer}>
                  <Text style={[FontStyles.caption1, { fontWeight: "400", color: Colors.gray.alpha07 }]}>
                    {"Cihaz:"}
                  </Text>
                  <Text style={[FontStyles.caption1, { fontWeight: "500", color: Colors.dark.alpha1 }]} selectable={true}>
                    {this.state.latestDeviceInfo ? (this.state.latestDeviceInfo.devicePlatform) + ' - ' + (this.state.latestDeviceInfo.deviceName) : "-"}
                  </Text>
                </View>
              </View>

              <View style={styles.infoContainer}>
                <Text style={[FontStyles.caption1, { fontWeight: "600", color: Colors.greenLight.alpha1 }]}>
                  Paket Bilgileri
                  { this.state.latestMembership && this.state.latestMembership.status === "EXPIRED" && <Text style={{color: Colors.gray.alpha07}}>
                    {" (Sona Ermiş)"}
                  </Text>}
                </Text>
                <View style={styles.infoRowContainer}>
                  <Text style={[FontStyles.caption1, { fontWeight: "400", color: Colors.gray.alpha07 }]}>
                    {"Paket Adı: "}
                  </Text>
                  <Text style={[FontStyles.caption1, { fontWeight: "500", color: Colors.dark.alpha1 }]} selectable={true}>
                    {this.state.latestMembership? this.state.latestMembership.source? this.state.latestMembership.source:"-":"-"}
                  </Text>
                </View>
                <View style={styles.infoRowContainer}>
                  <Text style={[FontStyles.caption1, { fontWeight: "400", color: Colors.gray.alpha07 }]}>
                    {"Başlangıç: "}
                  </Text>
                  <Text style={[FontStyles.caption1, { fontWeight: "500", color: Colors.dark.alpha1 }]} selectable={true}>
                    {this.state.latestMembership? this.state.latestMembership.createdAt? this.displayTimeWithTimezone(this.state.latestMembership.createdAt):"-":"-"}
                  </Text>
                </View>
                <View style={styles.infoRowContainer}>
                  <Text style={[FontStyles.caption1, { fontWeight: "400", color: Colors.gray.alpha07 }]}>
                    {"Bitiş: "}
                  </Text>
                  <Text style={[FontStyles.caption1, { fontWeight: "500", color: Colors.dark.alpha1 }]} selectable={true}>
                    {this.state.latestMembership? this.state.latestMembership.validUntil? this.displayTimeWithTimezone(this.state.latestMembership.validUntil):"-":"-"}
                  </Text>
                </View>
                {
                  this.state.latestMembership && this.state.latestMembership.subscription &&
                    <View style={styles.infoRowContainer}>
                      <Text style={[FontStyles.caption1, { fontWeight: "400", color: Colors.gray.alpha07 }]}>
                        {"Abonelik Tipi: "}
                      </Text>
                      <Text style={[FontStyles.caption1, { fontWeight: "500", color: Colors.dark.alpha1 }]} selectable={true}>
                        {this.state.latestMembership.subscription.interval === "MONTHLY" ? "AYLIK" : (this.state.latestMembership.subscription.interval === "YEARLY" ? "YILLIK" : this.state.latestMembership.subscription.interval)}
                      </Text>
                    </View>
                }
                {
                  this.state.latestMembership && this.state.latestMembership.subscription && this.state.latestMembership.subscription.status === "ACTIVE" &&
                  <View style={styles.infoRowContainer}>
                    <Text style={[FontStyles.caption1, { fontWeight: "400", color: Colors.gray.alpha07 }]}>
                      {"Abonelik Yenilenme: "}
                    </Text>
                    <Text style={[FontStyles.caption1, { fontWeight: "500", color: Colors.dark.alpha1 }]} selectable={true}>
                      {this.displayTimeWithTimezone(this.state.latestMembership.subscription.currentPeriodEndsAt)}
                    </Text>
                  </View>
                }
                {
                  (!this.state.latestMembership || !['ACTIVE','PARKED'].includes(this.state.latestMembership.status) || this.state.latestMembership.source === 'FREE-TRIAL' ) ?
                      <TouchableOpacity style={{paddingHorizontal:12,paddingVertical:8,backgroundColor:Colors.greenLight.alpha1,borderRadius:5, marginTop:4, alignSelf: 'flex-end', flexDirection: 'row', alignItems: 'center'}} onPress={()=>this.newMembershipModal.openModal()}>
                        <Feather name={"zap"} size={20} color={Colors.white.alpha1}/>
                        <Text style={[FontStyles.caption1,{marginLeft:6, color:Colors.white.alpha1}]}>
                          Yeni Üyelik Tanımla
                        </Text>
                      </TouchableOpacity>:
                      null
                }
                {
                  (!this.state.latestMembership || !['ACTIVE','PARKED'].includes(this.state.latestMembership.status) ) ?
                      <TouchableOpacity style={{paddingHorizontal:12,paddingVertical:8,backgroundColor:Colors.greenLight.alpha1,borderRadius:5, marginTop:12, alignSelf: 'flex-end', flexDirection: 'row', alignItems: 'center'}} onPress={()=>this.enterActivationCode()}>
                        <Feather name={"key"} size={20} color={Colors.white.alpha1}/>
                        <Text style={[FontStyles.caption1,{marginLeft:6, color:Colors.white.alpha1}]}>
                          Aktivasyon Kodu Gir
                        </Text>
                      </TouchableOpacity>:
                      null
                }
              </View>
              <View style={{ width: "100%", paddingHorizontal: 30, paddingBottom: 20 }}>
                <View style={{ flexDirection: "row", marginTop: 20, alignItems: "center" }}>
                  <Text style={[FontStyles.subhead, { flex: 1, fontWeight: "700", color: Colors.greenLight.alpha1 }]}>
                    Ort. Bellek Skorları
                  </Text>
                  <View style={styles.chartTypeButtonsContainer}>
                    <TouchableOpacity activeOpacity={0.9} onPress={() => this.setState({ chartType: 0 })} style={[styles.chartTypeButton, { backgroundColor: this.state.chartType==0? Colors.greenLight.alpha1:"transparent" }]}>
                      <Text style={[FontStyles.caption2, { fontWeight: "500", color: this.state.chartType==0? Colors.white.alpha1:Colors.dark.alpha1 }]}>
                        Bar
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity activeOpacity={0.9} onPress={() => this.setState({ chartType: 1 })} style={[styles.chartTypeButton, { backgroundColor: this.state.chartType==1? Colors.greenLight.alpha1:"transparent" }]}>
                      <Text style={[FontStyles.caption2, { fontWeight: "500", color: this.state.chartType==1? Colors.white.alpha1:Colors.dark.alpha1 }]}>
                        Çizgi
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={{ flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "space-evenly", marginTop: 15, paddingBottom: 20 }}>
                  {
                    this.state.assessmentData&&this.state.assessmentData.map((item, index) => {
                      let bg = this.state.ageKey ? item.userAverageScore > (item[this.state.ageKey.key + suffixForAgeRanges.avg]) - (item[this.state.ageKey.key + suffixForAgeRanges.std] * 2) ? Colors.greenLight : Colors.red : Colors.gray
                      

                      return (
                        <TouchableOpacity activeOpacity={0.9} onPress={() => this.setState({ selectedTestIndex: index })} key={index+"byn"} style={[styles.scoreContainer, { backgroundColor: this.state.selectedTestIndex==index? bg.alpha1:bg.alpha02 }]}>
                          <Text style={[FontStyles.footnote, { fontWeight: "800", color: this.state.selectedTestIndex==index? Colors.white.alpha1:Colors.dark.alpha1 }]}>
                            {(typeof item.userAverageScore === 'number') ? item.userAverageScore.toFixed(1):"-"}
                          </Text>
                          <Text style={[FontStyles.caption1, { marginTop: 5, fontWeight: "600", color: this.state.selectedTestIndex==index? Colors.white.alpha1:Colors.gray.alpha1 }]}>
                            {item.nameTurkish||"-"}
                          </Text>
                        </TouchableOpacity>
                      )
                    })
                  }
                </View>
                <View style={{ flex: 1, flexDirection: "row", width: "100%" }}>
                  {
                    this.state.assessmentData&&this.state.assessmentData[this.state.selectedTestIndex].results.length!=0?
                      this.state.chartType==0?
                        <TrackingGamesChart
                          data={this.state.assessmentData ? this.state.assessmentData[this.state.selectedTestIndex].results : null}
                          genericStats={this.state.assessmentData? this.state.assessmentData[this.state.selectedTestIndex] : null}
                          containerStyle={{ margin: 0, borderWidth: 2, borderColor: Colors.grayLight.alpha05 }}
                          title={this.state.assessmentData? this.state.assessmentData[this.state.selectedTestIndex].nameTurkish : null}
                          ageKey={this.state.ageKey}
                        />
                        :
                        <UserDetailTrackingLineChart
                          fromUserDetail
                          genericStats={this.state.assessmentData? this.state.assessmentData[this.state.selectedTestIndex]:null}
                          data={this.state.assessmentData? this.state.assessmentData[this.state.selectedTestIndex].results:null}
                          containerStyle={{ margin: 0, borderWidth: 2, borderColor: Colors.grayLight.alpha05 }}
                          title={this.state.assessmentData? this.state.assessmentData[this.state.selectedTestIndex].nameTurkish:null}
                        />
                      :null
                  }

                </View>
              </View>

              {this.state.assessmentData && <View style={[styles.infoRowContainer, {paddingHorizontal: 30}]}>
                <Text style={[FontStyles.caption1, { fontWeight: "400", color: Colors.gray.alpha07 }]}>
                  {"Bitirilen Tur Sayısı: "}
                </Text>
                <Text style={[FontStyles.caption1, { fontWeight: "500", color: Colors.dark.alpha1 }]} selectable={true}>
                  {Math.floor(Math.min.apply(Math, this.state.assessmentData.map(item=>item.results.length)) / 3)}
                </Text>
              </View>}

              <View style={[styles.infoRowContainer, {paddingHorizontal: 30}]}>
                <Text style={[FontStyles.caption1, { fontWeight: "400", color: Colors.gray.alpha07 }]}>
                  {"Son Demans Testi Sonucu: "}
                </Text>
                <Text style={[FontStyles.caption1, { fontWeight: "500", color: Colors.dark.alpha1 }]} selectable={true}>
                  {(this.state.dementiaTestResults.length > 0) ? (String(this.state.dementiaTestResults[0].totalScore) + " / 30") : "-"}
                </Text>
              </View>

              <View style={[styles.infoRowContainer, {paddingHorizontal: 30}]}>
                <Text style={[FontStyles.caption1, { fontWeight: "400", color: Colors.gray.alpha07 }]}>
                  {"BPI Skoru: "}
                </Text>
                <Text style={[FontStyles.caption1, { fontWeight: "500", color: Colors.dark.alpha1 }]} selectable={true}>
                  {(typeof this.state.beynexPerformanceIndex === 'number') ? this.state.beynexPerformanceIndex.toFixed(2) : "-"}
                </Text>
              </View>

            </View>

            {this.activeLifeQuestions()}
            {this.trainingPieChartCard()}
          </View>
          {
            !this.state.lightAdmin && this.state.userData.email && (this.state.userData.email.startsWith("deleted") && this.state.userData.email.endsWith("@beynex.com")) === false &&
                <TouchableOpacity style={{paddingHorizontal:12,paddingVertical:8,backgroundColor:Colors.red.alpha1,borderRadius:5, alignSelf: 'flex-end', marginRight:20, marginTop: 32,flexDirection:'row',alignItems:'center'}} onPress={()=>this.deleteUserModal.openModal()}>
                  <Feather name={"trash-2"} size={18} color={Colors.white.alpha1}/>
                  <Text style={[FontStyles.caption1, {marginLeft:8, color: Colors.white.alpha1}]}>
                    Kullanıcıyı sil
                  </Text>
                </TouchableOpacity>
          }
        </ScrollView>
        <Loading ref={ref => this.loading=ref} text={"Kullanıcı detayları getiriliyor"} />
        <Modal ref={ref => this.newMembershipModal = ref} containerStyle={{maxWidth: 800, maxHeight: 400}}>
          <View style={styles.inputContainer}>
            <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 24}}>
              <View style={{flexDirection:'row', alignItems: 'center'}}>
                <Text style={[FontStyles.body]}>
                  Demans testi verilsin:
                </Text>
                <Switch
                    trackColor={{false: Colors.red.alpha1, true: Colors.greenLight.alpha1}}
                    value={this.state.newMembershipEnableDementiaTest}
                    onValueChange={this.dementiaTestEnableSwitch}
                    style={{marginLeft:18}}
                />
              </View>
              <TouchableOpacity style={[styles.sendButton, {marginTop: 12}]} onPress={this.sendNewMembership} activeOpacity={0.9}>
                {
                  this.state.newMembershipSending ?
                      <ActivityIndicator color={"white"}/>
                      :
                      <Text style={[FontStyles.body, {color: Colors.white.alpha1, fontWeight: "600" }]}>
                        {"Tanımla"}
                      </Text>
                }
              </TouchableOpacity>
            </View>
            <Text style={[FontStyles.body, {marginTop: 24}]}>
              Süre:
            </Text>
            <View style={{flexDirection: 'row', marginTop: 6}}>
              <TextInput
                  ref={ref=>this.newMembershipIntervalCountInput=ref}
                  style={[FontStyles.body,{backgroundColor:Colors.grayLight.alpha05,borderRadius:6,padding:8, width: 40}]}
                  defaultValue={this.newMembershipIntervalCount}
                  maxLength={2}
                  onChangeText={(text)=>{this.newMembershipIntervalCount = text}}
              />
              <View style={[styles.buttonContainer, {marginLeft: 16}]}>
                <TouchableOpacity activeOpacity={0.9} onPress={() => this.setState({newMembershipInterval: "MONTHLY"})} style={[styles.intervalButton, {backgroundColor: this.state.newMembershipInterval == "MONTHLY" ? Colors.greenLight.alpha1 : "transparent"}]}>
                  <Text style={[FontStyles.body, { fontWeight: "500", color: this.state.newMembershipInterval == "MONTHLY" ? Colors.white.alpha1 : Colors.dark.alpha1 }]}>
                    Aylık
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity activeOpacity={0.9} onPress={() => this.setState({newMembershipInterval: "YEARLY"})} style={[styles.intervalButton, {backgroundColor: this.state.newMembershipInterval == "YEARLY" ? Colors.greenLight.alpha1 : "transparent"}]}>
                  <Text style={[FontStyles.body, { fontWeight: "500", color: this.state.newMembershipInterval == "YEARLY" ? Colors.white.alpha1 : Colors.dark.alpha1 }]}>
                    Yıllık
                  </Text>
                </TouchableOpacity>
              </View>
            </View>

            <Text style={[FontStyles.body, {marginTop: 24}]}>
              Tip:
            </Text>
            <View style={[styles.buttonContainer, {marginTop: 6,}]}>
              <TouchableOpacity activeOpacity={0.9} onPress={() => this.setState({newMembershipSource: "SPECIAL"})} style={[styles.intervalButton, {backgroundColor: this.state.newMembershipSource == "SPECIAL" ? Colors.greenLight.alpha1 : "transparent"}]}>
                <Text style={[FontStyles.body, { fontWeight: "500", color: this.state.newMembershipSource == "SPECIAL" ? Colors.white.alpha1 : Colors.dark.alpha1 }]}>
                  Special
                </Text>
              </TouchableOpacity>
              <TouchableOpacity activeOpacity={0.9} onPress={() => this.setState({newMembershipSource: "DIRECT-PAYMENT"})} style={[styles.intervalButton, {backgroundColor: this.state.newMembershipSource == "DIRECT-PAYMENT" ? Colors.greenLight.alpha1 : "transparent"}]}>
                <Text style={[FontStyles.body, { fontWeight: "500", color: this.state.newMembershipSource == "DIRECT-PAYMENT" ? Colors.white.alpha1 : Colors.dark.alpha1 }]}>
                  Direct-Payment
                </Text>
              </TouchableOpacity>
            </View>

          </View>
        </Modal>

        <Modal ref={ref => this.deleteUserModal = ref} containerStyle={{maxWidth: 400, maxHeight: 300}}>
          <View style={[styles.inputContainer, {width: '70%'}]}>
              <Text style={[FontStyles.callout, {marginTop: 48}]}>
                Bu işlem için Çok Faktörlü Doğrulama gereklidir.
              </Text>

              <View style={{flexDirection: "row", marginTop: 32, width: "100%",  alignItems: "center", borderRadius: 5, borderWidth: 1, borderColor: Colors.gray.alpha09, paddingHorizontal: "5%"}}>
                <Entypo name={"dial-pad"} size={20} color={Colors.gray.alpha1}/>
                <TextInput
                    ref={ref=>this.deleteUserMfaInput=ref}
                    selectionColor={Colors.greenLightDark.alpha1}
                    placeholderTextColor={Colors.gray.alpha07}
                    style={[FontStyles.footnote, styles.mfaInput]}
                    placeholder={"OTP Kodu"}
                    keyboardType={"number-pad"}
                    onChangeText={this.onChangeDeleteUserMfaText}
                    returnKeyType={"done"}
                    textContentType={"oneTimeCode"}
                    importantForAutofill={"no"}
                />
              </View>

            <TouchableOpacity style={styles.deleteUserButton} onPress={this.sendDeleteUser} activeOpacity={0.9}>
              {
                this.state.deleteUserSending ?
                    <ActivityIndicator color={"white"}/>
                    :
                    <Text style={[FontStyles.body, {color: Colors.white.alpha1, fontWeight: "600" }]}>
                      {"Sil: "}
                      <Text style={{fontStyle:"italic"}}>{" " + this.state.userData.username}</Text>
                    </Text>
              }
            </TouchableOpacity>
          </View>
        </Modal>
      </View>
    )
  }
};


const styles=StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 20
  },
  inputContainer:{
    width:'80%',
    alignSelf:'center',
    height:'100%',
    paddingVertical:'5%',
  },
  activeLifeContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    padding: 5,
    marginTop: 20,
    justifyContent: "space-evenly"
  },
  infoRowContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginVertical: 15
  },
  buttonContainer: {
    backgroundColor: Colors.grayLight.alpha05,
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
  },
  intervalButton: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: 46,
    width: 120,
    borderRadius: 5,
  },
  sendButton: {
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 12,
    paddingHorizontal: 16,
    width: 120,
    borderRadius: 5,
    backgroundColor: Colors.greenLight.alpha1,
  },
  pieContainer: {
    flexWrap: "wrap",
    backgroundColor: Colors.white.alpha1,
    borderRadius: 20,
    paddingHorizontal: 20,
    paddingVertical: 20,
    flexDirection: "row",
    marginTop: 20
  },  
  alqContainer: {
    backgroundColor: Colors.white.alpha1,
    borderRadius: 15,
    flex: 1,
    minWidth: 300,
    marginBottom: 10,
    marginRight: 10

  },
  chartTypeButtonsContainer: {
    backgroundColor: Colors.grayLight.alpha05,
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
  },
  chartTypeButton: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: 34,
    width: 60,
    borderRadius: 5,
  },
  userCardContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    backgroundColor: Colors.white.alpha1,
    borderRadius: 15,
    marginTop: 20,
    width: "100%",
  },
  mfaInput: {
    color: Colors.dark.alpha1,
    marginLeft: "5%",
    width: "100%",
    outline: "none",
    outlineWidth: 0,
    paddingVertical: 15,
    paddingHorizontal : "2.5%",
  },
  deleteUserButton: {
    backgroundColor: Colors.red.alpha1,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: "5%",
    paddingHorizontal: "5%",
    borderRadius: 5,
    width: "100%",
    marginTop: 12,
    minHeight: 52,
  },
  emailVerifyButton: {
    backgroundColor: Colors.greenLight.alpha1,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 6,
    paddingHorizontal: 4,
    borderRadius: 5,
    marginTop: -12,
    minWidth: 140,
    height: 36,
    alignSelf: "flex-end",
  },
  scoreContainer: {
    justifyContent: "center",
    alignItems: "center",
    minWidth: 80,
    flex: 1,
    backgroundColor: Colors.grayLight.alpha05,
    borderRadius: 5,
    paddingVertical: 12.5,
    marginRight: 5,
    marginTop: 5
  },
  userImage: {
    width: 90,
    height: 90,
    borderRadius: 45,
    backgroundColor: Colors.grayLight.alpha1
  },
  infoContainer: {
    flex: 1,
    padding: 20,
    minWidth: 325,
    borderBottomWidth: 2,
    borderRightWidth: 2,
    borderColor: Colors.grayLight.alpha03
  },
  imageContainer: {
    flex: 1,
    minWidth: 150,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 20,
    paddingVertical: 20,
    borderRightWidth: 2,
    borderBottomWidth: 2,
    borderColor: Colors.grayLight.alpha03
  },
  header: {
    height: 70,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 10,
    backgroundColor: Colors.white.alpha1,
    borderRadius: 15,
    alignSelf: "center",
    justifyContent: "space-between",
    overflow: "visible"
  },
  headerImg: {
    width: 48,
    height: 48,
    borderRadius: 24,
    marginRight: 20,
    backgroundColor: Colors.grayLight.alpha05
  },
  goBackButton: {
    height: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: Colors.white.alpha1,
    borderRadius: 10,
    paddingVertical: 5,
    paddingHorizontal: 15,
  },

})