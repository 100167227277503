import * as React from 'react';
import { View, Text, StatusBar, Dimensions, StyleSheet } from 'react-native';
import Colors from '../utils/Colors';
import FontStyles from "../utils/FontStyles"
import { createStackNavigator } from '@react-navigation/stack';

import Users from "./Users.js";
import Dashboard from "./Dashboard.js";
import Tickets from "./Support.js";
import UserDetail from "./UserDetail.js";
import PushNotification from './PushNotification';
import Blog from './Blog';
import BlogDetail from './BlogDetail';
import PlannedPushNotification from './PlannedPushNotification';
import NewAssessmentPushNotification from './NewAssessmentPushNotification';

import MenuButton from "../components/MenuButton";
import * as Device from 'expo-device';
import API from '../utils/API';
import LightAdmins from '../utils/LightAdmins.json';
import {Feather } from "@expo/vector-icons"

const buttonData=[
  {
    title: "Pano",
    iconName: "trending-up",
    routeName: "Dashboard",
    iconFamily: Feather,
  },
  {
    title: "Kullanıcılar",
    iconName: "users",
    routeName: "Users",
    iconFamily: Feather,
  },
  {
    title: "Bildirimler",
    iconName: "bell",
    routeName: "PushNotification",
    iconFamily: Feather,
  },
  {
    title: "Destek",
    iconName: "message-circle",
    routeName: "Support",
    iconFamily: Feather,
  },
  {
    title: "Blog",
    iconName: "book-open",
    routeName: "Blog",
    iconFamily: Feather,
  },
  {
    title: "Çıkış Yap",
    iconName: "log-out",
    routeName: "Login",
    iconFamily: Feather,
  }
]

const PHONE_VIEW_UPPER_WIDTH_BOUND = 950

export default class Home extends React.PureComponent {
  constructor(props) {
    super(props)
    this.selectedIndex=null;
    this.windowWidth = Dimensions.get('window').width
    this.originalDeviceType = null
    this.state={
      device: "DESKTOP",
      lightAdmin: true,
      userId: null,
      userReady:false
    };
  }
  
  onChange = ({ window }) => {
    this.windowWidth = window.width
    this.setState({ device: this.windowWidth < PHONE_VIEW_UPPER_WIDTH_BOUND ? "PHONE" : this.originalDeviceType })
  }

  componentDidMount() {
    this.getDeviceType()
    Dimensions.addEventListener("change", this.onChange)
    this.getCurrentUser();
  }
  
  componentWillUnmount() {
    Dimensions.removeEventListener("change", this.onChange)
  }

  getCurrentUser = () => {
    API.getCurrentUser().then((user)=>{
      this.setState({lightAdmin: LightAdmins.includes(user.id), userReady: true})
    }).catch((e)=>{
      
    })
  }

  getDeviceType=async () => {
    let device=await Device.getDeviceTypeAsync();
    this.windowWidth = Dimensions.get('window').width
    this.originalDeviceType = Device.DeviceType[device]
    this.setState({ device: this.windowWidth < PHONE_VIEW_UPPER_WIDTH_BOUND ? "PHONE" : this.originalDeviceType })
  }

  animateSelected=(item, index, shouldTriggerNavigate) => {
    if(item.routeName == "Login"){
      if(confirm("Çıkış yapmak istediğinizden emin misiniz?")){
        API.logout().then(() => {this.props.navigation.navigate(item.routeName)}).catch(() => {this.props.navigation.navigate(item.routeName)})
      }
    }else{
      console.log(this.selectedIndex);
      if(typeof this.selectedIndex === 'number' && this[`button${(this.selectedIndex)}`]){
        this[`button${(this.selectedIndex)}`].animateButton(0, !shouldTriggerNavigate);
      }
      if(this[`button${(index)}`]) {
        this[`button${(index)}`].animateButton(1, !shouldTriggerNavigate);
      }
      this.selectedIndex=index;
      if(shouldTriggerNavigate){
        this.props.navigation.navigate(item.routeName);
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.device !== this.state.device){
      let idx = this.selectedIndex;
      this.selectedIndex = null;
      this.animateSelected(buttonData[idx], idx, false);
    }
  }

  render() {
    const Stack = createStackNavigator();

    return (
      <View style={[styles.container, { flexDirection: this.state.device==="PHONE"? "column":"row" }]}>
        <StatusBar barStyle="light-content"/>
        {
          this.state.lightAdmin ? null :
          this.state.device !== "PHONE" ?
            <View style={styles.leftMenuContainer}>
              <Text style={[FontStyles.title3, { color: Colors.white.alpha1, marginBottom: "15%", fontWeight: "700" }]}>
                {"Beynex Panel"}
              </Text>
              {
                buttonData.map((item, index) => {
                  return (
                    <MenuButton device={this.state.device} title={item.title} iconName={item.iconName} iconFamily={item.iconFamily} onPress={() => this.animateSelected(item, index, true)} ref={ref => this[`button${(index)}`]=ref} firstSelected={false && index == this.selectedIndex} key={"s" + index}/>
                  )
                })
              }
            </View>
            :
            <View style={styles.leftMenuContainerMobile}>
              {
                buttonData.map((item, index) => {
                  return (
                    <MenuButton device={this.state.device} title={item.title} iconName={item.iconName} iconFamily={item.iconFamily} onPress={() => this.animateSelected(item, index, true)} ref={ref => this[`button${(index)}`]=ref} firstSelected={false && index == this.selectedIndex} key={"ss" + index} />
                  )
                })
              }
            </View>
        }

        <View style={styles.mainScreensContainer}>
          {
            this.state.userReady &&
            <Stack.Navigator headerMode="none">
              {this.state.lightAdmin===false ?
                  <Stack.Screen name="Dashboard"
                                component={Dashboard}
                                options={{title: "Pano | Beynex Panel"}}
                                listeners={({ navigation, route }) => ({
                                  transitionStart: e => {
                                    this.animateSelected(buttonData[0], 0, false);
                                  },
                                  focus: e => {
                                    this.animateSelected(buttonData[0], 0, false);
                                  },
                                })} /> :
                  undefined
              }
              <Stack.Screen name="Users"
                            component={Users}
                            options={{title: "Kullanıcılar | Beynex Panel"}}
                            listeners={({ navigation, route }) => ({
                              transitionStart: e => {
                                this.animateSelected(buttonData[1], 1, false);
                              },
                              focus: e => {
                                this.animateSelected(buttonData[1], 1, false);
                              },
                            })}/>
              {this.state.lightAdmin===false ?
                  <Stack.Screen name="Support"
                               component={Tickets}
                               options={{title: "Destek | Beynex Panel"}}
                               listeners={({ navigation, route }) => ({
                                 transitionStart: e => {
                                   this.animateSelected(buttonData[3], 3, false);
                                 },
                                 focus: e => {
                                   this.animateSelected(buttonData[3], 3, false);
                                 },
                               })}/> :
                  undefined}
              <Stack.Screen name="UserDetail"
                            component={UserDetail}
                            options={{title: "Kullanıcı | Beynex Panel"}}
                            listeners={({ navigation, route }) => ({
                              transitionStart: e => {
                                this.animateSelected(buttonData[1], 1, false);
                              },
                              focus: e => {
                                this.animateSelected(buttonData[1], 1, false);
                              },
                            })}
              />
              {this.state.lightAdmin===false ?
                  <Stack.Screen name="PushNotification"
                               component={PushNotification}
                               options={{title: "Bildirimler | Beynex Panel"}}
                               listeners={({ navigation, route }) => ({
                                 transitionStart: e => {
                                   this.animateSelected(buttonData[2], 2, false);
                                 },
                                 focus: e => {
                                   this.animateSelected(buttonData[2], 2, false);
                                 },
                               })}/> :
                  undefined}
              {this.state.lightAdmin===false ?
                  <Stack.Screen name="PlannedPushNotification"
                                component={PlannedPushNotification}
                                options={{title: "Planlanmış Bildirimler | Beynex Panel"}}
                                listeners={({ navigation, route }) => ({
                                  transitionStart: e => {
                                    this.animateSelected(buttonData[2], 2, false);
                                  },
                                  focus: e => {
                                    this.animateSelected(buttonData[2], 2, false);
                                  },
                                })}/> :
                  undefined}

              {this.state.lightAdmin===false ?
                  <Stack.Screen name="NewAssessmentPushNotification"
                                component={NewAssessmentPushNotification}
                                options={{title: "Yeni Değerlendirme Oyunu Bildirimleri | Beynex Panel"}}
                                listeners={({ navigation, route }) => ({
                                  transitionStart: e => {
                                    this.animateSelected(buttonData[2], 2, false);
                                  },
                                  focus: e => {
                                    this.animateSelected(buttonData[2], 2, false);
                                  },
                                })}/> :
                  undefined}
              {this.state.lightAdmin===false ?
                  <Stack.Screen name="Blog"
                                component={Blog}
                                options={{title: "Blog | Beynex Panel"}}
                                listeners={({ navigation, route }) => ({
                                  transitionStart: e => {
                                    this.animateSelected(buttonData[4], 4, false);
                                  },
                                  focus: e => {
                                    this.animateSelected(buttonData[4], 4, false);
                                  },
                                })}/> :
                  undefined}
              {this.state.lightAdmin===false ?
                  <Stack.Screen name="BlogDetail"
                            component={BlogDetail}
                            options={{title: "Blog Yazısı | Beynex Panel"}}
                            listeners={({ navigation, route }) => ({
                              transitionStart: e => {
                                this.animateSelected(buttonData[4], 4, false);
                              },
                              focus: e => {
                                this.animateSelected(buttonData[4], 4, false);
                              },
                            })}/> :
                  undefined}
            </Stack.Navigator>
          }
 
        </View>
      </View>
    )
  }
};


const styles=StyleSheet.create({
  container: {
    flex: 1,
  },
  leftMenuContainer: {
    height: "100%",
    borderTopRightRadius: 25,
    borderWidth: 2,
    borderColor: Colors.white.alpha05,
    borderBottomRightRadius: 25,
    backgroundColor: Colors.greenLight.alpha1,
    paddingVertical: 40,
    paddingLeft: 20,
    paddingRight: 20
  },
  leftMenuContainerMobile: {
    width: "100%",
    height: 60,
    borderBottomWidth: 2,
    borderColor: Colors.white.alpha05,
    backgroundColor: Colors.greenLight.alpha1,
    paddingHorizontal: 20,
    flexDirection: "row",
    alignItems: "center"
  },
  mainScreensContainer: {
    flex: 1,
    height: "100%",
  },
})
