import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import NewUserChartCard from "../components/NewUserChartCard"
import TrainingGamesChart from "../components/TrainingGamesChart"
import TrackingGamesLineChart from "../components/TrackingGamesLineChart"
import UserInfoCard from "../components/UserInfoCard"
import { ScrollView } from 'react-native-gesture-handler';
import Loading from "../components/Loading";
import API from '../utils/API';
import moment from "moment"
import {CommonActions} from "@react-navigation/native";

export default class Dashboard extends React.PureComponent {
  constructor(props) {
    super(props)
    
    this.state = {
      genericStats: {},
      playedAssessmentGameCountsByDay: null,
      playedTrainingGameCountsByDay: null,
      registrationCountsByDay: null,
    }
  }

  componentDidMount() {
    this.getData()
  }

  sortRegistrationCounts = (d) => {
    let intermed = []
    let keys = Object.keys(d)
    for(let i in keys){
      intermed.push({date: moment(keys[i]).format('X'), value: d[keys[i]]})
    }
    return intermed
  }

  getData = () => {
    this.loading.show(); // on mount, it's already showing. but we need to call this because in reloads we need this.
    API.getStats().then((response) => {
      this.setState({
        genericStats: response.genericStats,
        playedAssessmentGameCountsByDay: this.sortRegistrationCounts(response.playedAssessmentGameCountsByDay),
        playedTrainingGameCountsByDay: this.sortRegistrationCounts(response.playedTrainingGameCountsByDay),
        registrationCountsByDay: this.sortRegistrationCounts(response.registrationCountsByDay),
      }, () => {
        this.loading.hide();
      })
    }).catch((e) => {
      this.loading.hide();
      if(e.errorCode && e.errorCode === 7){
        alert('Oturumunuz kapatılmış veya süresi dolmuş. Tekrar giriş yapmak için yönlendiriliyorsunuz.');
        this.props.navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{ name: "Login" }]
            })
        )
      }
    })
  }

  render() {
    return (
      <View style={{flex: 1}}>
        <ScrollView style={{flex: 1}}>
          <View style={styles.container}>
            <View style={styles.gamesContainer}>
              <NewUserChartCard
                reloadData={this.getData}
                genericStats={this.state.genericStats}
                data={this.state.registrationCountsByDay}
                title="Kullanıcı İstatistikleri"
              />
              <UserInfoCard
                reloadData={this.getData}
                data={this.state.genericStats}
                title="Genel İstatistikler"
              />
            </View>
            <View style={styles.gamesContainer}>
              <TrainingGamesChart
                reloadData={this.getData}
                genericStats={this.state.genericStats}
                data={this.state.playedTrainingGameCountsByDay}
                title="Egzersiz Oyunları"
              />
              <TrackingGamesLineChart
                reloadData={this.getData}
                genericStats={this.state.genericStats}
                data={this.state.playedAssessmentGameCountsByDay}
                title="Takip Testleri"
              />
            </View>
          
          </View>
        </ScrollView>
        <Loading ref={ref => this.loading = ref} text={"İstatistikler yükleniyor"}/>
      </View>
      
    )
  }
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },
  gamesContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  }
})